.header .logo {
  height: 70px;
  width: 70px;
  object-fit: contain;
}
.active-tab{
  font-weight: 900;
}
.header {
  position: fixed;
  width: 100%;
  height: 90px;
  background-color: #272727;
  z-index: 1;
}
.navbar-nav {
  padding-right: 280px;
}
.headerContainer {
  height: 100% !important;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center !important;
  padding-left: 80px;
}
.playstoreContainers {
  /* height: 100%; */
  display: flex;
  align-items: center;
  padding-right: 50px;
  position: absolute;
  right: 10px;
}

.googlePlay {
  height: 50px;
  margin-right: 10px;
  cursor: pointer;
}

.googlePlay img {
  height: 100%;
}
.playStore {
  height: 50px;
  cursor: pointer;
}
.playStore img {
  height: 100%;
  /* width: 100%; */
}
.menuContainer {
  justify-content: center;
  position: relative;
  /* width: 766px; */
}
.menuContainer li {
  color: white;
  font-size: 14px;
}
.menuContainer a {
  width: 100px;
}

.navbar-toggler {
  position: absolute;
  right: 20px;
  border: 1px solid #f4dba0 !important;
}

.navbar-toggler-icon {
  background-color: #f4dba0 !important;
  border-radius: 4px;
}

.navbar-toggler:focus {
  /* box-shadow: 0px 5px 10px white !important; */
  border: 4px solid #a58750 !important;
  box-shadow: none !important;
}

@media screen and (max-width: 991px) {
  .logocontainer {
    padding-left: 20px;
  }
  .playstoreContainers {
    left: 0px;
    right: 0px;
    padding: 10px 20px;
  }
  .headerContainer {
    padding-left: 0px;
    padding-right: 0px;
  }
  .navbar-collapse {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 1180px) and (min-width: 1024px) {
  .navbar-nav {
    width: 100%;
    padding-left: 10%;
  }
  .menuContainer a {
    /* width: 0%; */
    width: initial;
    /* padding-left: 20px; */
  }
  
}

@media screen and (min-width:1400px) and (max-width:1490px){
  .header{
    height:110px;
  }
  .menuContainer a {
    width: 128px;
}
.menuContainer li{
  font-size: 18px;

  font-weight: 400;
}
.googlePlay{
  height:70px;
  width: 210px;
}
.playStore{
  height:70px;
  width: 210px;
}
.navbar-nav{
  padding-right:37%;
}
}

@media screen and (min-width: 1500px) {
  .header{
    height:130px;
  }
  .menuContainer li{
    font-size: 18px;
    font-weight: 400;
  }
  .header .logo
  {
    height: 80px;
    width:80px;
  }
  .menuContainer a {
    width: 190px;
  }
  .menuContainer li{
    font-size:18px;
  }
  .googlePlay{
    height:60px;

  width: 190px;
  }
  .playStore{
    height:60px;

  width: 190px;
  }
  .navbar-nav{
    padding-right:300px;
    max-width: 1000px;
    flex-direction: left;
  }
  
  
  }