html {
  --defaultGradientColor: linear-gradient(to left, #f4dba0, #a58750);
}

.backGround-room {
  background: url("../Assets/RoomImage/RoomImage.png");
  /* background-size: 100% 100%; */
  width: 100%;
  /* height: 680px; */
  height: 630px;
  /* object-fit: cover; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /* opacity: 0.85; */
}

.otpHeight {
  height: 100vh !important;
  background-repeat: repeat !important;
}

.image-overLay {
  /* background-color: #00000099; */
  background-color: #000000c7;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  /* opacity: 0.85; */
}

.text-default {
  color: white;
}

.download-section {
  background: url("../Assets/Mobilebackground.png");

  width: 100%;
  height: 680px;
  /* object-fit: contain; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  background-repeat: no-repeat;
  background-color: #090909;
}

.playStoreImage {
  width: 30%;
  display: flex;
  margin-top: 80px;
}

.playStoreImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.first-text {
  position: absolute;
  top: 55%;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.collection_text {
  color: white;
  font-size: 2.5rem;
  font-family: "HelveticaNeue-extraLight", "Helvetica Neue extraLight", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  letter-spacing: -3px;



  /* font-weight: 600; */
}

.appstore-container {
  margin-left: 10px;
}

.editor-choice {
  font-size: 1rem;
  /* justify-content: center; */
  text-align: center;
  padding: 0px 11.25rem 0px 11.25rem;
}

.editor-choice-text {
  background-color: white;
  color: black;
  border-radius: 0.938rem;
  text-align: center;
  font-size: 1rem;
}

.below-collection {
  font-size: 0.75rem;
  font-weight: 400;
}

.try-for {
  margin-top: 2.5rem;
  font-size: 0.75rem;
  color: black;
  background: linear-gradient(70deg, #f4dba0, #a58750);
  font-weight: 600;
}

.form-div {
  background-color: #1d1d1d;
}

.team-image {
  width: 100%;
}

.contact-us {
  color: white;
  /* text-align: center; */
  width: 50%;
  padding-right: 6.25rem;
}

.circle-div {
  background: url("../Assets/circle.png");
  width: 100%;
  height: 640px;
  background-color: black;
  /* object-fit: contain; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.circle-text {
  height: 100%;
}

.feature_image_size {
  height: 70px;
  width: 70px;
}

.videoImage {
  height: 640px;
  width: 100%;
}

.video-div {
  background-color: black;
}

.footer {
  background: url("../Assets/Mask\ Group\ 2.png");
  width: 100%;
  height: 300px;
  background-color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer-logo {
  width: 100px;
  height: 100px;
}

.team-image-div {
  padding: 5rem 1.875rem 5rem 1.875rem;
  width: 50%;
}

/* .contact-div{
    width:50%
} */
.awesome-features {
  font-weight: 600;
  font-size: 2.5rem;
  color: white;
}

.awesome-feature-belowText {
  font-weight: 400;
  color: white;
  font-size: 0.938rem;
}

.explore-outfits {
  margin-top: 0.438rem;
  margin-bottom: 0.625rem;
  font-size: 1.25rem;
  color: #D0A443;
  font-weight: 600;
}

.explore-outfits-text {
  color: white;
  font-size: 0.875rem;
}

.contact-us-btn {
  font-size: 0.75rem;
  color: black;
  background: linear-gradient(70deg, #f4dba0, #a58750);
  font-weight: 600;
  border: none;
  padding: 0.625rem 0.625rem 0.625rem 0.625rem;
}

.why-season {
  color: white;
  font-size: 2.5rem;
  font-weight: 600;
}

.why-season-div {
  padding: 2.5rem 3.125rem 2.5rem 3.125rem;
  background: #1D1D1D;
}

.download-btn {
  font-size: 0.75rem;
  color: black;
  background: linear-gradient(70deg, #f4dba0, #a58750);
  font-weight: 600;
  border: none;
  padding: 0.625rem 3.125rem 0.625rem 3.125rem;
}

.download-app {
  font-size: 2.5rem;
  font-weight: 600;
}

.download-app-div {
  padding: 2.5rem 3.125rem 2.5rem 3.125rem;
}

.download-app-text {
  font-weight: 400;
}

.contact-us-text {
  font-size: 2.5rem;
  font-weight: 600;
}

.contact-usMedia {}

.send-btn {
  font-size: 0.75rem;
  color: black;
  background: linear-gradient(70deg, #f4dba0, #a58750);
  font-weight: 600;
  border: none;
  padding: 0.625rem 3.75rem 0.625rem 3.75rem;
}

.footer-options {
  padding: 0rem 15.625rem 0rem 15.625rem;
  text-decoration: none !important;

}

a {
  text-decoration: none !important;
  color: white !important;
}

@media screen and (max-width: 1023px) {
  .contact-us {
    padding-top: 1.25rem;
    padding-bottom: 1.875rem;
    padding-right: 3.25rem;
  }

  .footer-options {
    padding: 0rem 3.625rem 0rem 3.625rem;

  }

}

@media screen and (max-width: 767px) {
  .collection_text {
    font-size: 1.5rem;
  }

  .editor-choice-text {
    font-size: 0.6rem;
  }

  .feature_image_size {
    height: 60px;
    width: 60px;
  }

  .explore-outfits {
    font-size: 0.8rem;
  }

  .explore-outfits-text {
    font-size: 0.6rem;
  }

  .footer-options {
    padding: 0rem 1.625rem 0rem 1.625rem;
    font-size: 0.7rem;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .footer-options a {

    margin-top: 10px
  }

  .below-collection {
    font-size: 0.65rem;
  }

  .awesome-features {
    font-size: 1.5rem;
  }

  .awesome-feature-belowText {
    font-size: 0.65rem;
    /* padding: 0px 1.25rem; */
  }
}

@media screen and (max-width: 425px) {
  .first-text {
    /* font-size: 1.6rem; */
    width: 100%;
    padding: 0px 1.25rem;
  }

  .circle-div {
    padding: 0px 1.25rem;
    height: auto;
    padding-top: 30px;
  }

  .contact-us-btn {
    width: 40%;
  }

  .videoImage {
    height: auto;
  }

  .why-season {
    font-size: 1.5rem;
  }

  .why-season-details-container {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    /* justify-content: center; */
  }

  .why-season-details {
    display: flex;
    flex-direction: column;
  }

  .why-season-detail {
    font-size: 0.7rem;
  }

  .why-season-container-image {

    margin-top: 20px;
  }


  .download-app {
    font-size: 1.5rem;
  }

  .download-app-detail {
    font-size: 0.65rem;
    width: 50%;
  }

  .download-section {
    height: auto;
  }

  .download-app-div {
    padding: 0px 1.25rem;
  }

  .contactUs-container {
    /* display: flex; */
    flex-direction: column;
    padding: 2rem 1.25rem;
    /* height: auto; */
  }

  .team-image-div {
    width: 100%;
    padding: 2rem 1.25rem;
  }

  .contact-us {
    flex-direction: column;
    padding: 0px;
    width: 100%;
  }

  /* .contact-us-info {
    display: flex;
  } */
  .send-btn-container {
    display: flex;
    /* width: 100%; */
    justify-content: center;
  }

  .contact-us-text {
    font-size: 1.5rem;
  }

  .contact-us-detail {
    font-size: 0.65rem;
  }

  .playStoreImage {
    margin-top: auto;
    padding-bottom: 20px;
  }

  .copyright {
    background-color: #1D1D1D;
    height: 50px;
    /* margin-bottom: 20px; */
    color: white;
    text-align: center;
    font-size: 0.8rem;
  }
}

@media (min-width:650px) and (max-width:800px) {
  .why-season-details-container {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    /* justify-content: center; */
  }

  .why-season-details {
    display: flex;
    flex-direction: column;
  }

  /* .why-season-detail {
  font-size: 0.7rem;
} */
  .why-season-container-image {

    margin-top: 20px;
  }
}

@media screen and (min-width: 991px) {

  .first-text {
    width: 100%;
  }

  .editor-choice-text {
    font-size: 1.5rem
  }

  .why-season-all-text {
    font-size: 18px;
    font-weight: 300;
  }

  .why-season {
    font-size: 3.2rem;
  }

  .awesome-features {
    font-size: 3.2rem;

  }

  .collection_text {
    font-size: 3.2rem;
  }

  .download-app {
    font-size: 3.2rem;
  }

  .contact-us-text {
    font-size: 3.2rem;
  }

  .awesome-feature-belowText {
    font-size: 22px;
  }

  .download-btn {
    font-size: 1.2rem;
    padding: 0.8rem 4.125rem 0.8rem 4.125rem
  }

  .download-app-detail {
    font-size: 22px;
  }

  .feature_image_size {
    height: 100px;
    width: 100px;
  }

  .explore-outfits {
    font-size: 1.6rem;
  }

  .explore-outfits-text {
    font-size: 1rem;
  }

  .contact-us-btn {
    font-size: 1.2rem;
    padding: 0.8rem 1.125rem 0.8rem 1.125rem;
  }

  .contact-us-detail {
    font-size: 22px;
  }

  .contactUs-container {
    padding: 40px 0px 40px 0px;
  }

  .contact-us {
    padding-right: 2.1rem;
  }

  .send-btn {
    font-size: 1.2rem;
    padding: 0.625rem 4.75rem 0.625rem 4.75rem;
  }

  .below-collection {
    font-size: 22px;
  }

  .try-for {
    font-size: 1.2rem;
    padding: 0.8rem 2.5rem 0.8rem 2.5rem !important;

  }

  .footer {
    height: 350px;
  }

  .footer-logo {
    width: 150px;
    height: 150px
  }

  .footer-options {
    padding: 0rem 10.625rem 0rem 10.625rem;
    font-size: 18px;
  }

  .playStoreImage {
    width: 40%;
    margin-top: 50px;
  }

  .why-season-container-image {
    padding-left: 35px;
  }

  .footer-logo {
    height: 200px;
    width: 200px;
    /* margin-bottom: 2.5rem!important; */
  }

  .footer-image {
    margin-bottom: 2.5rem !important;
  }


}

@media screen and (min-width:1462px) {
  .backGround-room {
    height: 800px;
    /* height: 1080px; */
  }

  .editor-choice-text {
    width: 400px;
    height: 44px;
    border-radius: 23px;
    font-size: 1.5rem;
  }

  .collection_text {
    font-size: 4.375rem;
    margin-top: 1.5rem;
  }

  .below-collection {
    font-size: 1.313rem;
    margin-top: 1.5rem;

  }

  .try-for {
    font-size: 1.375rem;
    height: 4.375rem;
    width: 17.5rem;
    margin-top: 4.6rem
  }

  .circle-div {
    height: 850px
  }

  .awesome-features {
    font-size: 3.75rem;
  }

  .awesome-feature-belowText {
    font-size: 1.375rem;
  }

  .feature_image_size {
    height: 8.125rem;
    width: 8.125rem;
  }

  .explore-outfits {
    font-size: 1.625rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    ;
  }

  .explore-outfits-text {
    font-size: 1.25rem;
  }

  .contact-us-btn {
    font-size: 1.375rem;
    width: 280px;
    height: 70px;
  }

  .videoImage {
    height: 70vh;
    /* width: 100xh; */
  }

  .contact-usMedia {
    margin-top: 90px !important;
  }

  .video-div {
    padding-top: 2.5rem !important;
    padding-bottom: 6rem !important;
    padding-right: 10rem !important;
    padding-left: 10rem !important;

  }

  .why-season {
    font-size: 3.75rem;
  }

  .why-season-detail {
    font-size: 24px;
  }

  .why-season-container-image {
    /* height:600px;
 width: 600px; */
    /* padding-left: 40px; */
    /* margin-left: 70px; */
  }

  .why-season-container-image img {
    width: 100%;
    height: 100%
  }

  .why-season-div {
    padding: 2.5rem 3.125rem 2.5rem 10.125rem;
    /* height: 950px; */
  }

  .why-season-all-text {
    padding-right: 15px;
    font-weight: 300;
  }

  .download-btn {
    width: 280px;
    height: 70px;
    font-size: 22px;
    padding: 0rem 0rem 0rem 0rem;
  }

  .download-section {
    height: 1000px;

  }

  .download-app {
    font-size: 3.75rem;
  }

  .download-app-detail {
    font-size: 22px;
    /* width: 50%; */
  }

  .download-app-div {
    padding: 12.5rem 3.125rem 2.5rem 12rem
  }

  .form-div {
    height: 1020px;
  }

  .contact-us-text {
    font-size: 60px;
  }

  .contact-us-detail {
    font-size: 22px;
  }

  .input-field {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    border-radius: 5px;
  }

  .text-area {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
    border-radius: 5px;
    margin-bottom: 7rem !important;

  }

  .send-btn {
    width: 280px;
    height: 70px;
    font-size: 22px;
  }

  .team-image {
    height: 664px;

  }

  .footer {
    height: 500px;
  }

  .footer-logo {
    width: 200px;
    height: 200px;
  }

  .footer-options {
    font-size: 22px;
  }


}

.copyright {
  background-color: #1D1D1D;
  height: 50px;
  /* margin-bottom: 20px; */
  color: white;
  text-align: center;
  font-size: 1rem;
}

@media (max-width: 768px) {

  .whySeason {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
  }

  .WhyCon {
    flex-direction: column;
    align-items: center;
  }

  .backGround-room {
    height: 530px;
  }

  .below-collection {
    font-size: 0.75rem;
  }

  .collection_text {
    font-size: 1.8rem;
  }

  .downloadButton {
    margin: 0 auto;
  }
}

.boxborder {
  padding: 34px 15px;
  border: 5px solid #D0A443;
  width: 58%;
  margin: 0 auto;

}

@media (max-width: 991px) {
  .boxborder {

    width: 102%;

  }
}


.weight {
  font-size: 1.3rem;
}

.shadow {

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.imgOTP {
  height: 40rem;
}


@media (max-width: 1440px) {
  .imgOTP {
    height: 25rem;
  }
}

@media (max-width: 991px) {
  .imgOTP {
    height: 25rem;
  }
}

@media (max-width: 769px) {
  .imgOTP {
    height: 25rem;
  }
}
